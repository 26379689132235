@import styles/_variables

.container
  border-top: 1px solid rgba(#000, .1)
  flex-grow: 0
  flex-shrink: 0
  margin: 1.5rem 0
  padding-top: 1rem
  max-width: calc($layout-width - 2rem)
  width: 100%
  @media (max-width: 600px)
    margin-bottom: 80px

  :global(.logo)
    align-items: center
    display: flex
    justify-content: center
    margin-top: 1em

    img
      background: none !important
      height: auto
      width: 91px

  :global(.slogan)
    display: flex
    justify-content: center

    p
      max-width: 75%
      text-align: center
      color: grey
      margin-top: 8px


