@import styles/_variables

.container
  align-items: stretch
  background-color: #fff
  border-top: 1px solid var(--grey-color)
  bottom: 0
  box-shadow: 0 0 4px rgba(#000, .2)
  display: none
  height: 56px // Android compliant height (ios 49px)
  justify-content: center
  left: 0
  position: fixed
  right: 0
  z-index: 3

  @media (max-width: 600px)
    display: flex

.item
  align-items: center
  display: flex
  justify-content: center
  width: 33.3%

.itemStaging
  width: 25%

.itemActive a
  color: $color-primary

.link
  color: gray
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  text-decoration: none
  font-size: 24px
  padding-left: 1em
  padding-right: 1em

.icon
  font-size: 20px
  margin-top: 2px

.label
  font-size: 11px
  margin-top: .5em
