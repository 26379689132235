.content
  text-align: center

.congratulations
  font-size: 1.1em
  font-weight: bold

.imageContainer
  position: relative

  :global(.image-vayapin)
    color: #fff
    font-size: 2rem
    font-weight: bold
    left: 0
    position: absolute
    right: 0px
    text-align: center
    text-shadow: 0 5px 10px rgba(#000, .4)
    top: 60px
