.Container
  border-radius: 8px
  background-color: #fff
  text-align: center
  box-shadow: 0 2px 8px rgba(#000, .3)
  margin-bottom: 16px
  overflow: hidden
  width: 100%

.overflowAuto
  overflow: auto

.overflowVisible
  overflow: visible

.overflowHidden
  overflow: hidden

.overflowClip
  overflow: clip

.overflowScroll
  overflow: scroll
