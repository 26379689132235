.cardContent
  overflow: hidden
  padding: 24px

.first
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.last
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px

.noPadding
  padding: 0

.overflowAuto
  overflow: auto

.overflowVisible
  overflow: visible

.overflowHidden
  overflow: hidden

.overflowClip
  overflow: clip

.overflowScroll
  overflow: scroll
